import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { UserTableProps, UserTableComponent } from "./components/usertable";
import { lazyInject, TYPES, ListStates, fmtMsg } from "../../../util/index";
import { IUserService, GetUserRequest, UserModelPropNames } from "../../../service/users/index";
import { UserFieldSelectComponent } from "./components/userform";
import { withRouter, ResourceOperation, PaginationParams, isGuid, GLGlobal, RoleName } from "gl-commonui";
import { ListStatesStore, MainTitle, Container } from "@app/components";
import { GSAdminLocale } from "@app/locales/localeid";
import { GLGridSorter } from "@app/components/gl-grid";
import { RegionInfoModel } from "@app/service/class";

interface UsersPageProps extends RouteComponentProps<any> {}
interface UsersPageStates {
    loading?: boolean;
    resCmd?: ResourceOperation;
    listState: ListStates;
    allRegions: RegionInfoModel[];
}
@withRouter
export class UsersPage extends Component<UsersPageProps, UsersPageStates> {
    @lazyInject(TYPES.IUserService)
    service: IUserService;
    pagination: PaginationParams = new PaginationParams(1, 20);
    userModal = null;
    selectedRole = null;
    filterName = "";
    currentRegionId = "";
    selectedRegion = "";
    currentTenantId = 0;
    tableProps: UserTableProps = {
        handleTablePageChange: this.handleTablePageChange.bind(this),
        resOpers: {
            default: () => {
                //this.userModal = null;
                //this.setState({ resCmd: ResourceOperation.default });
                this.setTableProps();
            },
            create: () => {},
            update: (value) => {},
            delete: (value) => {},
            select: (value) => {},
        },
        dataForExporting: [],
    };
    listStateStore: ListStatesStore;
    listCnts: any = {};
    currentCall: number = 0;
    sortColumn: string; // sort grid
    isAsending: boolean; // sort grid
    totalItemsOnGrid = 0;
    showPendingUser: boolean = false;
    constructor(props) {
        super(props);
        const selectedUser = JSON.parse(sessionStorage.getItem("selectedUser") || "{}");
        this.selectedRole = selectedUser.selectedRole ? selectedUser.selectedRole : this.selectedRole;
        this.filterName = selectedUser.filterName ? selectedUser.filterName : this.filterName;
        this.selectedRegion = selectedUser.regionInfo ? selectedUser.regionInfo.name : this.selectedRegion;
        this.currentRegionId = selectedUser.regionInfo ? selectedUser.regionInfo.id : this.currentRegionId;
        this.currentTenantId = selectedUser.tenantId ? selectedUser.tenantId : this.currentTenantId;
        sessionStorage.removeItem("selectedUser");
        this.listStateStore = new ListStatesStore("users-list-states", this.selectedRole || "users-list-states");
        this.state = {
            listState: this.listStateStore.getState(),
            allRegions: GLGlobal.loginInfo().profile.regionInfo || [],
        };
    }

    componentDidMount() {
        this.getAccessibleRegions();
        this.setTableProps();
    }

    async getAccessibleRegions() {
        this.setState({
            allRegions: GLGlobal.loginInfo().profile.regionInfo || []
        })
    }

    getTenantId(value?) {
        if(this.state.allRegions && this.state.allRegions.length) {
            // if user has only one region assigned
            if(!value) {
                this.selectedRegion = this.state.allRegions[0].name;
                this.currentTenantId = this.state.allRegions[0].tenantId;
                this.currentRegionId = this.state.allRegions[0].id;
                return this.currentTenantId
            }
            const selectedRegion = this.state.allRegions.filter(r => r.id === value || r.name === value)[0];
            this.selectedRegion = selectedRegion.name;
            this.currentTenantId = selectedRegion.tenantId;
            return this.currentTenantId
        }
        // if value is empty string
        return this.currentTenantId;
    }

    setTableLoading(isLoading: boolean = true) {
        this.tableProps.loading = isLoading;
        this.setState({ loading: isLoading });
    }

    handleTablePageChange(pagination: any) {
        this.pagination.current = pagination.current;
        this.setTableProps();
    }

    setTableProps(getAllData: boolean = false) {
        let params = this.listStateStore.getUrlQueryParams(new GetUserRequest(this.pagination), this.showPendingUser);
        params.tenantId = this.getTenantId(this.currentRegionId)
        params.regionId = this.currentRegionId;
        if (getAllData) {
            params.offset = 0;
            params.limit = null;
        }

        if (this.sortColumn) {
            params.order = this.sortColumn;
            params.isAsc = this.isAsending;
        }

        this.setTableLoading();

        if (!this.selectedRole) {
            return this.setTabledata({
                totalCount: 0,
                data: [],
                extraData: { activeCnt: 0, inActiveCnt: 0, pendingCount: 0 },
            });
        }

        params.role = this.selectedRole == "all" ? "" : this.selectedRole;
        params.email = params.userName = params.phone = this.filterName == "" ? "" : this.filterName;
        params.isOnlyLowerRoles = true;
        this.currentCall += 1;
        const localCurrentCall = this.currentCall;
        this.totalItemsOnGrid = 0;
        if (params.role === RoleName.parent) {
            this.service.getParentChildrenList(params).then((data) => {
                if (this.currentCall === localCurrentCall) {
                    this.setTabledata(data);
                }
            }).catch(error => {
                this.setTableLoading(false);
            });
        }
        else {
            this.service.getUsersList(params).then((data) => {
                if (this.currentCall === localCurrentCall) {
                    this.setTabledata(data);
                }
            }).catch(error => {
                this.setTableLoading(false);
            });
        }
    }

    setTabledata(data) {
        this.listCnts.allCnt = data.totalCount;
        this.listCnts.activeCnt = data.extraData.activeCnt;
        this.listCnts.inActiveCnt = data.extraData.inActiveCnt;
        this.listCnts.pendingCount = data.extraData.pendingCount;
        this.tableProps.pagination = this.pagination;
        this.tableProps.pagination.total = ListStatesStore.getStateCnt(
            data.totalCount,
            data.extraData.activeCnt,
            this.state,
            0,
            data.extraData.pendingCount,
            data.extraData.inActiveCnt
        );
        this.tableProps.dataSource = this.service.formatUserList(data.data, this.selectedRegion);
        this.totalItemsOnGrid = this.tableProps.dataSource.length;
        this.setTableLoading(false);
    }

    changeListState({ values, state }) {
        this.listStateStore.setStateKey(values.role);
        this.listStateStore.setState(state);
        this.setState({ listState: state });

        this.pagination.current = 1;
        this.selectedRole = values.role;
        this.filterName = values.name;
        if(isGuid(values.region)) {
            this.currentRegionId = values.region;
        }
        this.setTableProps();
    }

    onChildChanged(values) {
        this.changeListState({ values, state: this.listStateStore.getState(values.role) });
    }

    resetExportingData() {
        this.tableProps.dataForExporting = [];
        this.tableProps.exportingData = false;
        this.setTableLoading(false);
    }

    onColumnSorting(sorter: GLGridSorter) {
        if (!sorter || sorter.columnName == "role" || sorter.columnName == UserModelPropNames.childrenList) {
            return;
        }
        this.sortColumn = sorter.columnName;
        this.isAsending = sorter.ascending;
        this.setTableProps(false);
    }

    exportUsers = () => {
        if (this.totalItemsOnGrid === 0) {
            return;
        }
        let params = this.listStateStore.getUrlQueryParams(new GetUserRequest(this.pagination), this.showPendingUser);
        params.offset = 0;
        params.limit = null;
        params.tenantId = this.getTenantId(this.currentRegionId);
        params.regionId = this.currentRegionId;
        if (this.sortColumn) {
            params.order = this.sortColumn;
            params.isAsc = this.isAsending;
        }

        this.setTableLoading();

        params.role = this.selectedRole == "all" ? "" : this.selectedRole;
        params.email = params.userName = params.phone = this.filterName == "" ? "" : this.filterName;

        if (params.role === RoleName.parent) {
            this.service.getParentChildrenList(params).then((data) => {
                this.tableProps.dataForExporting = this.service.formatUserList(data.data, this.selectedRegion);
                this.tableProps.exportingData = true;
                this.setTableLoading(false);
            }).catch(error => {
                this.setTableLoading(false);
            });
        }
        else {
            this.service.getUsersList(params).then((data) => {
                this.tableProps.dataForExporting = this.service.formatUserList(data.data, this.selectedRegion);
                this.tableProps.exportingData = true;
                this.setTableLoading(false);
            }).catch(error => {
                this.setTableLoading(false);
            });
        }
    };

    onFetchPendingCheckboxClick(e) {
        this.showPendingUser = e.state === ListStates.Pending ? true : false;
        this.changeListState({ values: e.values, state: e.state })
    }

    render() {
        const params = {
            history: this.props.history,
            selectedRole: this.selectedRole,
            filterNameOrEmailOrPhone: this.filterName,
            allCnt: this.listCnts.allCnt,
            activeCnt: this.listCnts.activeCnt,
            inActiveCnt: this.listCnts.inActiveCnt,
            listState: this.state.listState,
            pendingCount: this.listCnts.pendingCount,
            exportUsers: this.exportUsers.bind(this),
            resetExportingData: this.resetExportingData.bind(this),
            sortDataOnGrid: this.onColumnSorting.bind(this),
            totalItemsOnGrid: this.totalItemsOnGrid,
            currentRegionId: this.currentRegionId,
            selectedRegion: this.selectedRegion,
            tenantId: this.currentTenantId,
            allRegions: this.state.allRegions,
            showPendingUser: this.showPendingUser,
            onFetchPendingCheckboxClick: this.onFetchPendingCheckboxClick.bind(this)
        };
        return (
            <Container fullWidth className="user-list-page">
                <MainTitle plain={fmtMsg({ id: GSAdminLocale.UsersListTitle })} />
                <UserFieldSelectComponent
                    params={params}
                    changeListState={this.changeListState.bind(this)}
                    callbackParent={(values: any) => this.onChildChanged(values)}
                ></UserFieldSelectComponent>
                <UserTableComponent {...this.tableProps} params={params}></UserTableComponent>
                {this.userModal}
            </Container>
        );
    }
}
