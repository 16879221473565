import * as React from 'react'
import { Component } from 'react';
import { lazyInject, TYPES, GSAdminAction, fmtMsg, CONSTS, ListStates } from '../../../../util/index';
import { Row, Select, Form, Button, Input, Col, Icon, Checkbox } from "antd-min";
import { GLForm, GLFormComponentProps, FormHelper, FormItemsLayout, ResourceOperation, GLGlobal, RoleName, GLAction } from "gl-commonui";
import { UserFormModelNames, IUserService } from '../../../../service/users/index';
import { Link } from "react-router-dom";
import { AdminPathConfig as PathConfig } from "../../../../config/pathconfig";
import { ListStatesRadios, MainTitle } from '../../../../components';
import { GSAdminLocale, SchoolLocale, DashboardLocale } from '../../../../locales/localeid';
import "./userform.less";

export interface UserFieldSelectProps {
    params?: any
    callbackParent?: React.MouseEventHandler<any>
    changeListState: (s) => void
}
interface UserFieldSelectStates {
    defaultRole?: string
    defaultNameOrEmailOrPhone?: string
    roleOptions?: JSX.Element[] | null
    resCmd?: ResourceOperation
    visible?: boolean
    regions?: JSX.Element[] | null
    defaultRegion?: string
    showPendingUser?: boolean
}

@GLForm.create()
export class UserFieldSelectComponent extends Component<UserFieldSelectProps & GLFormComponentProps, UserFieldSelectStates> {
    @lazyInject(TYPES.IUserService)
    service: IUserService
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }
    componentDidMount() {
        this.setRoleList();
        this.setRegionList();
    }
    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.callbackParent(values);
            }
        });
    }
    setRoleList() {
        // Only the System Admin should be able to see System Admin + All other roles in the dropdown.
        // User with any other role than SystemAdmin should only see roles lower than his role in select dropdown.
        let roles = GLGlobal.loginInfo().profile.roles;
        if (roles.indexOf(RoleName.systemAdmin) > -1) {
            this.setRolesByRole(CONSTS.Role.keys());
        } else if (roles.indexOf(RoleName.globalHead) > -1) {
            this.setRolesByRole([RoleName.trainingAdmin, RoleName.regionAdmin, RoleName.accountManager, RoleName.trainer, RoleName.trainingManager, RoleName.schoolAdmin,
            RoleName.campusAdmin, RoleName.teacher, RoleName.parent]);
        } else if (roles.indexOf(RoleName.regionAdmin) > -1) {
            this.setRolesByRole([RoleName.accountManager, RoleName.trainer, RoleName.trainingManager, RoleName.schoolAdmin,
            RoleName.campusAdmin, RoleName.teacher, RoleName.parent]);
        } else if (roles.indexOf(RoleName.accountManager) > -1) {
            this.setRolesByRole([RoleName.trainer, RoleName.schoolAdmin, RoleName.campusAdmin, RoleName.teacher, RoleName.parent]);
        } else if (roles.indexOf(RoleName.trainer) > -1) {
            this.setRolesByRole([RoleName.schoolAdmin,
            RoleName.campusAdmin, RoleName.teacher])
        } else if (roles.indexOf(RoleName.trainingAdmin) > -1) {
            this.setRolesByRole([RoleName.trainer, RoleName.trainingManager]);
        }
    }
    setRolesByRole(keys) {
        let tempRoleOptions = ["all", ...keys].map(key => {
            return key == "all" ? this.getOption("All", "all") :
                key == "Parent" ? this.getOption("Parent and Children", key) :
                this.getOption(CONSTS.Role.get(key as RoleName), key);
        });

        this.setState({
            defaultNameOrEmailOrPhone: this.props.params.filterNameOrEmailOrPhone ? this.props.params.filterNameOrEmailOrPhone : "",
            defaultRole: this.props.params.selectedRole ? this.props.params.selectedRole : null,
            roleOptions: tempRoleOptions
        });
    }
    setRegionList() {
        if(this.props.params.allRegions) {
            const allRegions = this.props.params.allRegions;
            let tempRegions = allRegions.map(r => {
                return this.getOption(r.name, r.id)
            })
            this.setState({
                defaultRegion: this.props.params.selectedRegion ? this.props.params.selectedRegion : "",
                regions: tempRegions,
            })
        }
    }
    getOption(item, index) {
        return <Select.Option key={index} value={index}>{item}</Select.Option>
    }
    fetchPendingUsers(e) {
        this.setState({
            showPendingUser: e.target.checked
        })

        if(e.target.checked) {
            this.props.params.onFetchPendingCheckboxClick({ values: this.props.form.getFieldsValue(), state: ListStates.Pending })
        }
        else {
            this.props.params.onFetchPendingCheckboxClick({ values: this.props.form.getFieldsValue(), state: ListStates.Active })
        }
    }
    getNoOfColumns(){
        return this.state.regions && this.state.regions.length > 1 ? 3 : 2;
    }

    render() {
        const form = this.props.form;
        const { renderFormItem } = FormHelper;
        const fmt = GLGlobal.intl.formatMessage;
        return <>
            <Row>
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <Col xs={24} sm={16} lg={13} className="usertable">
                        <FormItemsLayout colTotal={this.getNoOfColumns()} >
                            {this.state.regions && this.state.regions.length > 1 ? renderFormItem(form, GSAdminLocale.UserModelRegion, UserFormModelNames.region,
                                <Select>{...this.state.regions}</Select>, this.state.defaultRegion, true) : <></>}
                            {renderFormItem(form, GSAdminLocale.UserRoleFieldName, UserFormModelNames.role,
                                <Select>{...this.state.roleOptions}</Select>, this.state.defaultRole, true)}
                            {renderFormItem(form, GSAdminLocale.UserNameFieldName, UserFormModelNames.name,
                                <Input />, this.state.defaultNameOrEmailOrPhone)}
                        </FormItemsLayout>
                    </Col>
                    <Button type='primary' className='user-search' htmlType="submit">{fmt({ id: GSAdminLocale.UsersListSearch })}</Button>
                </Form>
            </Row>
            <Row className="users-list-states">
                <Col xs={24} sm={16}>
                    <ListStatesRadios
                        allCnt={this.props.params.allCnt}
                        activeCnt={this.props.params.activeCnt}
                        inActiveCnt={this.props.params.inActiveCnt}
                        pendingCount={this.props.params.pendingCount}
                        value={this.props.params.listState}
                        showPendingRadio={this.state.showPendingUser}
                        showAllRadio={!this.state.showPendingUser}
                        showActiveRadio={!this.state.showPendingUser}
                        showInActiveRadio={!this.state.showPendingUser}
                        onChange={(state) => {
                            this.props.changeListState({ values: form.getFieldsValue(), state });
                        }}></ListStatesRadios>
                </Col>
                <Col xs={24} sm={8}>
                    <Row type="flex" justify="end" align="middle">
                        <GLAction action={GSAdminAction.Invitation}>
                            <Col>
                                <Link to={{ pathname: PathConfig.Invitations }} >
                                    <Icon type="mail" className="actionicon"></Icon>
                                    <span style={{ marginLeft: "5px" }}>{fmt({ id: GSAdminLocale.AvatarMenInvitations })}</span>
                                </Link>
                            </Col>
                        </GLAction>
                        <Col>
                            <div className='pending-text ml-15'>
                                <Checkbox checked={this.state.showPendingUser} onChange={(e) => this.fetchPendingUsers(e)}>
                                <span style={{ verticalAlign: "bottom" }}>{fmt({ id: GSAdminLocale.PendingUsersText })} </span>
                                </Checkbox>
                            </div>
                        </Col>
                        <Col>
                            <div className='dashboard-role-grid-export ml-15'>
                                <a onClick={() => this.props.params.exportUsers()}
                                    className={this.props.params.totalItemsOnGrid === 0 ? 'disabled-link' : ''}>
                                    <Icon type="download" /><span> {fmtMsg({ id: DashboardLocale.LandingTabExportText })}</span>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    }
}