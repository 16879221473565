import * as React from 'react'
import { Component } from 'react';
import { AdminPathConfig as PathConfig } from "../../../../config/pathconfig";
import { lazyInject, TYPES, GSAdminAction, fmtMsg, CONSTS } from '../../../../util/index';
import { GLGlobal, PaginationParams, IResourceOperation, GLUtil, RoleName, GLAction } from "gl-commonui";
import { UserModel, UserModelPropNames, IUserService } from '../../../../service/users/index';
import { GSAdminLocale, SchoolLocale } from '../../../../locales/localeid';
import { GLGrid, FlexGrid, CellType, FlexGridXlsxConverter } from '@app/components/gl-grid';
import { PaginationConfig } from 'antd/lib/table';
import { AllowDragging } from 'wijmo/wijmo.grid';
import { WijmoGrid } from "@app/components/grid";

const { Column } = WijmoGrid;

export interface UserTableProps {
    onlyView?: boolean
    loading?: boolean
    dataSource?: UserModel[],
    dataForExporting?: UserModel[],
    exportingData?: boolean,
    pagination?: PaginationParams
    handleTablePageChange?: (pagination: any) => void
    resOpers?: IResourceOperation<UserModel>
    params?: any
}
interface UserTableStates {
    tableLayout: any,
    currentPage: number
}
export class UserTableComponent extends Component<UserTableProps, UserTableStates> {
    @lazyInject(TYPES.IUserService)
    service: IUserService
    tableLayout: any = {
        bordered: false,
        onChange: this.props.handleTablePageChange,
        rowClassName: (record) => record.disabled ? "list-disabled" : "",
        onRow: (record: UserModel, index) => {
            this.props.resOpers.select(record);
        }
    };

    private paginationConfig: PaginationConfig;
    private grid4Export: FlexGrid;
    constructor(props, context) {
        super(props, context);
        this.state = {
            tableLayout: null,
            currentPage: 1
        };
        this.onGridLoaded = this.onGridLoaded.bind(this);
        this.onLoadedRowGrid4Export = this.onLoadedRowGrid4Export.bind(this);
    }

    RemoveTrainer(record) {
        this.service.removeRole({ id: record.id, rolename: RoleName.trainer }).then(data => {
            this.tableLayout.dataSource = this.props.dataSource.map(item => {
                if (item.id == record.id) {
                    item.roles = item.roles.filter(role => role != RoleName.trainer);
                    item.role = item.roles.join(", ");
                }
                return item;
            });
            this.setState({ tableLayout: this.tableLayout });
        });
    }

    addTrainer(record) {
        this.service.addRole({ id: record.id, rolename: RoleName.trainer }).then(data => {
            this.tableLayout.dataSource = this.props.dataSource.map(item => {
                if (item.id == record.id) {
                    item.roles.push(RoleName.trainer);
                    item.role = item.roles.join(", ");
                }
                return item;
            });
            this.setState({ tableLayout: this.tableLayout });
        });
    }

    renderOperations(text, record, index) {
        const userRoles = new Set(record.roles);
        return (
            <React.Fragment>
                <GLAction action={GSAdminAction.EditUser}>
                    <span className="icon-edit action" title="Edit User" key={1} onClick={() => this.redirectToEditUserPage(record)} />
                </GLAction>
                {userRoles.has(RoleName.trainer) && GLGlobal.isActionValid(GSAdminAction.DeleteTrainerRole) &&
                    <React.Fragment>
                        <span title="Remove Trainer" key={0} className="icon-delete-trainer action" onClick={() => this.RemoveTrainer(record)}>
                            <span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                    </React.Fragment>
                }
                {!userRoles.has(RoleName.trainer) && GLGlobal.isActionValid(GSAdminAction.AddTrainerRole) &&
                    <span title="Invite Trainer" key={0} className="icon-invite-trainer action" onClick={() => this.addTrainer(record)} />
                }
            </React.Fragment>
        );
    }

    redirectToEditUserPage(record) {
        this.props.params.history.push({
            pathname: GLUtil.pathStringify(PathConfig.UserEdit, { userId: record.id }), state: {
                selectedRole: this.props.params.selectedRole,
                filterName: this.props.params.filterNameOrEmailOrPhone,
                regionName: this.props.params.selectedRegion
            }
        });
    }

    getRowKey = (record, index) => {
        return record.id === CONSTS.EmptyGuid ? `${record.id}${index}` : record.id;
    }

    getAllGlGridColumns(excludeChildrenCol: boolean) {
        let columns = [
            {
                key: 0,
                title: fmtMsg({ id: GSAdminLocale.UserModelName }),
                dataIndex: UserModelPropNames.name,
                width: "20%",
                className: "username",
                allowDragging: false,
                render: (text, record, index) => {
                    if (record.isInvitePending) {
                        return text && typeof text === "string" && text.length > 0 ? `${text} ${fmtMsg({ id: SchoolLocale.AdminsPending })}` : "";
                    }

                    const path = GLUtil.pathStringify(PathConfig.UserEdit, { userId: record.id });
                    return <a href={path}>{record.name}</a>;
                }
            },
            {
                key: 1,
                title: fmtMsg({ id: GSAdminLocale.UserModelChildren }),
                dataIndex: UserModelPropNames.childrenList,
                width: "20%",
                allowDragging: false,
                render: (text, record, index) => {
                    return text != null ? `${text}` : <></>
                }
            },
            {
                key: 2,
                title: fmtMsg({ id: GSAdminLocale.UserModelEmail }),
                dataIndex: UserModelPropNames.email,
                width: "20%",
                allowDragging: false,
                render: (text, record, index) => {
                    return text && (record.isInvitePending ? `${text} ${fmtMsg({ id: SchoolLocale.AdminsPending })}` : text);
                }
            },
            {
                key: 3,
                title: fmtMsg({ id: GSAdminLocale.UserModelPhone }),
                dataIndex: UserModelPropNames.phone,
                width: "20%",
                allowDragging: false,
                render: (text, record, index) => {
                    return record && record.phone && (record.isInvitePending ? `${record.phone} ${fmtMsg({ id: SchoolLocale.AdminsPending })}` : record.phone);
                }
            },
            {
                key: 4,
                title: fmtMsg({ id: GSAdminLocale.UserModelRole }),
                dataIndex: UserModelPropNames.role,
                width: "20%",
                sorter: false,
                render: (text, record, index) => {
                    return <div className="textcss">{text}</div>;
                }
            }
        ];

        // remove children column if role other than Parent is selected
        if (excludeChildrenCol) {
            columns.splice(1, 1);
        }

        return columns;
    }

    getAllGlGridColumnsForExport(excludeChildrenCol: boolean) {
        let columns = [
            {
                key: 5,
                title: fmtMsg({ id: GSAdminLocale.UserModelName }),
                dataIndex: UserModelPropNames.name,
                width: "20%",
                className: "username",
                allowDragging: false,
                render: (text, record, index) => {
                    if (record.isInvitePending) {
                        return text && typeof text === "string" && text.length > 0 ? `${text} ${fmtMsg({ id: SchoolLocale.AdminsPending })}` : "";
                    }

                    const path = GLUtil.pathStringify(PathConfig.UserEdit, { userId: record.id });
                    return <a href={path}>{record.name}</a>;
                }
            },
            {
                key: 6,
                title: fmtMsg({ id: GSAdminLocale.UserModelChildren }),
                dataIndex: UserModelPropNames.childrenList,
                width: "20%",
                allowDragging: false,
                render: (text, record, index) => {
                    return text != null ? `${text}` : <></>
                }
            },
            {
                key: 7,
                title: fmtMsg({ id: GSAdminLocale.UserModelEmail }),
                dataIndex: UserModelPropNames.email,
                width: "20%",
                allowDragging: false,
                render: (text, record, index) => {
                    return text && (record.isInvitePending ? `${text} ${fmtMsg({ id: SchoolLocale.AdminsPending })}` : text);
                }
            },
            {
                key: 8,
                title: fmtMsg({ id: GSAdminLocale.UserModelPhone }),
                dataIndex: UserModelPropNames.phone,
                width: "20%",
                allowDragging: false,
                render: (text, record, index) => {
                    return record && record.phone && (record.isInvitePending ? `${record.phone} ${fmtMsg({ id: SchoolLocale.AdminsPending })}` : record.phone);
                }
            },
            {
                key: 9,
                title: fmtMsg({ id: GSAdminLocale.UserModelRole }),
                dataIndex: UserModelPropNames.role,
                width: "20%",
                sorter: false,
                render: (text, record, index) => {
                    return <div className="textcss">{text}</div>;
                }
            }
        ];

        // remove children column if role other than Parent is selected
        if (excludeChildrenCol) {
            columns.splice(1, 1);
        }

        if(this.props.params.allRegions && this.props.params.allRegions.length > 1) {
            columns.push({
                key: 10,
                title: fmtMsg({ id: GSAdminLocale.UserModelRegion }),
                dataIndex: UserModelPropNames.region,
                width: "20%",
                sorter: false,
                render: (text, record, index) => {
                    return <div className="textcss">{text}</div>;
                }
            });
        }

        return columns;
    }

    onChangePagingBar = (pageIndex: any) => {
        this.props.handleTablePageChange({ ...this.props.pagination, current: pageIndex });
        this.setState({ currentPage: pageIndex });
    }

    onGlGridClick = () => {
        const selectedUser = {
            selectedRole: this.props.params.selectedRole,
            filterName: this.props.params.filterNameOrEmailOrPhone,
            regionInfo: { id: this.props.params.currentRegionId, name: this.props.params.selectedRegion },
            tenantId: this.props.params.tenantId
        }

        sessionStorage.setItem("selectedUser", JSON.stringify(selectedUser));
    }

    onFormatItem(item) {
        if (item.panel.cellType == CellType.ColumnHeader) {
            item.xlsxCell.style.font = { bold: true };
        }
    }

    onLoadedRowGrid4Export = () => {
        if (this.props.exportingData && this.props.dataForExporting && this.props.dataForExporting.length > 0) {
            const exportFileName = 'UserList.xlsx';
            FlexGridXlsxConverter.saveAsync(
                this.grid4Export,
                {
                    includeColumnHeaders: true,
                    includeCellStyles: false,
                    formatItem: this.onFormatItem
                },
                exportFileName
            );
            this.props.params.resetExportingData();
        }
    }

    onGridLoaded(grid) {
        this.grid4Export = grid;
    }

    renderGridForExport = () => {
        return (
            <div className="d-none">
                <GLGrid
                    allowDragging={AllowDragging.Columns}
                    columnComponents={this.getGridForExport()}
                    dataSource={this.props.dataForExporting}
                    pagination={false}
                    loading={this.props.loading}
                    onGridLoaded={this.onGridLoaded}
                    onLoadedRows={this.onLoadedRowGrid4Export}
                />
            </div>
        )
    }

    getTypeOfGrid(selectedRole) {
        return selectedRole === RoleName.parent
    }

    getGrid() {
        const result = this.getTypeOfGrid(this.props.params.selectedRole) 
                        ? 
                        this.getAllGlGridColumns(false).map(x => (
                            <Column
                                key={x.key}
                                binding={x.dataIndex}
                                header={x.title}
                                render={x.render}
                            />
                        ))
                        :
                        this.getAllGlGridColumns(true).map(x => (
                            <Column
                                key={x.key}
                                binding={x.dataIndex}
                                header={x.title}
                                render={x.render}
                            />
                        ))

        return result;
    }

    getGridForExport() {
        const result = this.getTypeOfGrid(this.props.params.selectedRole) 
                        ? 
                        this.getAllGlGridColumnsForExport(false).map(x => (
                            <Column
                                key={x.key}
                                binding={x.dataIndex}
                                header={x.title}
                                render={x.render}
                                allowDragging={x.allowDragging}
                                wordWrap={true}
                            />
                        ))
                        :
                        this.getAllGlGridColumnsForExport(true).map(x => (
                            <Column
                                key={x.key}
                                binding={x.dataIndex}
                                header={x.title}
                                render={x.render}
                                allowDragging={x.allowDragging}
                                wordWrap={true}
                            />
                        ))

        return result;
    }

    render() {
        if (!this.props.dataSource || !this.props.pagination) {
            return "";
        }
        this.paginationConfig = {
            total: this.props.pagination.total,
            current: this.props.pagination.current,
            pageSize: this.props.pagination.pageSize,
            onChange: this.onChangePagingBar.bind(this)
        };

        return (
            <div>
                <GLGrid
                    allowDragging={AllowDragging.Columns}
                    allowGrouping={false}
                    columnComponents={this.getGrid()}
                    onColumnSorting={this.props.params.sortDataOnGrid}
                    dataSource={this.props.dataSource}
                    sortInClient={false}
                    pagingInClient={false}
                    pagination={{ ...this.paginationConfig, current: this.props.pagination.current == 1 ? 1 : this.state.currentPage }}
                    loading={this.props.loading}
                    onGridClick={this.onGlGridClick.bind(this)}
                />
                {
                    this.renderGridForExport()
                }
            </div>
        )
    }
}
